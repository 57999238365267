.ant-tabs-nav {
    padding-left: 0;
}
.ant-tabs-tabpane {
    flex: none;
    width: 100%;
    outline: none;
    border: 0.25px solid #fff;
    border-bottom: none !important;
    border-left: none;
    border-right: none;
    /* padding-top: 5px; */
}
.ant-table-thead > tr > th {
    color: #0c173a;
    background: #FFFFFF;
    border-bottom: 1px solid #f0f0f0;
    font-size: 12px !important;
    font-weight: 500 !important;
}