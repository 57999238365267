@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.main-row{
    padding: 0 6rem;
   
}
@media only screen and (min-width: 993px) {
    .main-row{
        padding: 0 14rem;
       
    }
}
.main-heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #192228;
    padding: 1rem 0;
}
.container {
    position: relative;
    background: url("../../../assets/images/roller.png") no-repeat;
    background-size: cover;
    background-position: left top !important; /* Aligns the image to the top-left corner of the div */
    height: 20em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
  }

.left-div {
    background: #0C173A;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 4px 4px #EBEBEB;
    border-radius: 10px 0px 0px 10px;
    padding: 3rem;

}

.leftSide-line-div {
    background: linear-gradient(180deg, #313332 0%, #3D3D3D 26.04%, #3D3D3D 49.48%, #3D3D3D 72.92%, #363636 100%);
    border-radius: 100px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    margin: 20vh auto;
    width: 50%;
}

.leftSide-line-color {
    background: linear-gradient(90deg, #3DF79D 0%, #CFF226 100%);
    border-radius: 100px 3px 3px 100px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    height: 1vh;
    align-self: unset;
    width: 50%;

}

.right-divs {
    background: #FFFFFF;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.11);
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 4px;
    cursor: pointer;
    /* height: 33%; */
}

.right-div {
    background: #D9D9D9;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 4px 4px #EBEBEB;
    border-radius: 0px 10px 10px 0px;
    padding: 2rem 1rem;
}

.left-sec-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px !important;
    color: #FFFFFF;

}

.right-sec-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #192228;
}

.right-img-div {
    background: #D9D9D9;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    padding: 1rem;
    width: 7vw;
    height: 14vh;
}
.save-button{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;

color: #192228;
background: #0C173A;
box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
border-radius: 4px;
}
.lable-text{
font-family: Inter;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
color: #000000;

}
.input-inside-text{
    font-family: Inter !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 14px !important;
line-height: 15px !important;
color: #0C173A !important;
width: 60%;
height: 4.5vh;
border-radius: 3px !important;

}
.getStarted.ant-select-selection-item{
    font-size: 14px !important;
}
.ant-collapse-header, .ant-collapse-content-box {
    border: none !important;
  }
  .manage-store-card{
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }
  .tax-address-text{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;    
    color: #0C173A;
    
  }
  /* .ant-collapse-content > .ant-collapse-content-box {
    padding: 10px 16px;
} */