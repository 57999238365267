.font-footer{
    font-family: 'Oxygen', sans-serif;
}
.mainLayoutPadding1 {
    padding-top: 0.7em;
    padding-bottom: 15px;
    padding-right: 0.9em;
    padding-left: 0.9em;
}.font-footer{
    font-family: 'Oxygen', sans-serif;
}
.alertsPadding {
    padding-top: 0;
    padding-bottom: 15px;
    padding-right: 0.7em;
    padding-left: 0em;
}
.mainLayoutDashboardPadding1 {
    padding-top: 0px;
    padding-bottom: 15px;
    padding-right: 23px;
    padding-left: 23px; 
}
.mainLayoutReportPadding {
    padding-right: 23px;
    padding-left: 23px; 
}
.mainLayoutReportPadding1 {
    padding-top: 15px;
    padding-right: 13px;
    padding-left: 0px; 
}
.ant-layout {
    background: #f3f4f9;
}

.lineMenu > .ant-menu-light .ant-menu-item:hover,
.lineMenu .ant-menu-light .ant-menu-item-active,
.lineMenu .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.lineMenu .ant-menu-light .ant-menu-submenu-active,
.lineMenu .ant-menu-light .ant-menu-submenu-title:hover {
    background-color: #DCDFEE !important;
    color: #0A0213 !important;
    border-radius: 7px;
}

.mainLayoutDashboardPadding1 {
    padding-top: 0px;
    padding-bottom: 15px;
    padding-right: 23px;
    padding-left: 23px; 
}
.mainLayoutReportPadding {
    padding-right: 23px;
    padding-left: 23px; 
}
.mainLayoutReportPadding1 {
    padding-top: 15px;
    padding-right: 13px;
    padding-left: 0px; 
}
.ant-layout {
    background: #f3f4f9;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-left: 10px;
}
.ant-menu-item-group-list{
    padding-left: 15px !important;
}