.tableContainer {
    width: 80%;
    margin: auto;
  }
  h1,
  h2 {
    text-align: center;
  }
  .tableContainer table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  .tableContainer th,
  .tableContainer td {
    border: 0.5px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  .tableContainer th {
    background-color: #b5b5b5;
  }
  .tableContainer td {
    background-color: #ffffff;
  }
